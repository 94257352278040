


























import { Component, Vue, Watch } from 'vue-property-decorator'
import { debounce } from 'lodash-es'
import { RouteNames } from '@/router'

@Component
export default class Footer extends Vue {
  get version():string {
    const { branch, version, abbreviatedSha } = INFO
    return `${branch}:${version}:${abbreviatedSha}`
  }

  numberOfClicks = 0

  resetNumberOfClicks = debounce(function(this:Footer) {
    this.numberOfClicks = 0
  }, 3000)

  @Watch('numberOfClicks')
  watchNumberOfClicks():void {
    const max = 10
    if (this.numberOfClicks >= max) {
      this.$router.push({ name: RouteNames.development })
    }
    if (this.numberOfClicks >= 5) {
      this.$bvToast.toast(`Кликните ещё ${max - this.numberOfClicks} раз`, {
        title: 'Info',
        variant: 'info',
        autoHideDelay: 300
      })
    }
    this.resetNumberOfClicks()
  }

  get DATE_BUILD() {
    return new Date(INFO.dateBuild).toLocaleString()
  }
}
